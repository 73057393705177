*,
html {
  scroll-behavior: smooth;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --black: #000;
  --lite: rgba(255, 255, 255, 0.6);
  --gray: rgba(1, 1, 1, 0.6);
  /* --dark:#101010; */
  --primary: linear-gradient(145deg, #002347, #3399ff);
  --primary_dark: #002347;
  --primary_lite: #3399ff;
  --secondary: #3ab068;
  --default_font: "Mukta", sans-serif;
  --title_font: "Kanit", sans-serif;
}

::-webkit-scrollbar {
  height: 12px;
  width: 8px;
  background: var(--dark);
}

::-webkit-scrollbar-thumb {
  background: gray;
  -webkit-box-shadow: 0px 1px 2px var(--dark);
}

::-webkit-scrollbar-corner {
  background: var(--dark);
}

/***************************
          DEFAULT
****************************/

a {
  text-decoration: none !important;
  min-width: fit-content;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
}

a,
button {
  transition: 0.5s;
}

em {
  font-style: normal;
  color: var(--primary_lite);
}

ul {
  padding: 0;
}

figure {
  padding: 0;
  margin: 0;
}

a,
p,
.btn {
  font-size: 15px;
}

p {
  line-height: 1.9em;
  /* color:var(--lite); */
}

a,
button,
input,
textarea,
select {
  outline: none !important;
}

fieldset {
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.title,
.sub_title {
  font-family: var(--title_font);
  font-weight: 400;
  margin: 0;
}

.flex,
.fixed_flex {
  display: flex;
}

.flex_content {
  width: 100%;
  position: relative;
}

.padding_1x {
  padding: 1rem;
}

.padding_2x {
  padding: 2rem;
}

.padding_3x {
  padding: 3rem;
}

.padding_4x {
  padding: 4rem;
}

.big {
  font-size: 3.5em;
}

.medium {
  font-size: 2em;
}

.small {
  font-size: 1.1em;
}

.btn {
  padding: 1rem;
  border-radius: 5px;
  color: var(--white);
  position: relative;
  border: 0;
  text-align: center;
  font-weight: 500;
}

.btn_1 {
  background: var(--primary_lite);
  color: var(--dark) !important;
}

.btn_1:hover {
  opacity: 0.8;
}

.btn_2 {
  background-color: #fdc632;
  color: var(--dark) !important;
}

.btn_2:hover {
  opacity: 0.8;
  color: var(--dark);
}

.btn_3 {
  display: block;
  background-color: 0;
  color: var(--white);
  position: relative;
  font-family: var(--default_font);
  font-weight: 400;
  text-transform: uppercase;
}

.btn_3:before {
  content: "";
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 0;
  top: 50%;
  width: 50px;
  height: 50px;
  transition: 0.5s;
  transform: translate(0%, -50%);
}

.btn_3:after {
  content: "\f178";
  font-family: "FontAwesome";
  margin-left: 5px;
}

.btn_3:hover:before {
  border-radius: 40px;
  width: 100%;
}

.divisions {
  position: relative;
}

.title_header {
  margin: auto;
  text-align: center;
  width: 60%;
}

.ball:before {
  content: "";
  border-radius: 50%;
  background-color: rgba(1, 1, 1, 0.2);
  position: absolute;
  left: 0;
  top: 50%;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
}

.link-tag {
  position: relative;
  color: var(--black);
}

.link-tag:before {
  content: "\f0da";
  font-family: "FontAwesome";
  margin-right: 5px;
  transition: 0.5s;
  color: var(--primary);
}

.link-tag:hover:before {
  margin-right: 10px;
  color: var(--black);
}

.link-tag:hover {
  color: var(--primary);
}

@media (max-width: 920px) {
  .flex {
    flex-wrap: wrap;
  }

  .padding_1x,
  .padding_2x,
  .padding_3x,
  .padding_4x {
    padding: 1rem;
  }

  .big {
    font-size: 1.8em;
  }

  .medium {
    font-size: 1.6em;
  }

  .small {
    font-size: 1.1em;
  }

  .btn {
    padding: 0.5rem 1rem;
  }

  a,
  p,
  .btn {
    font-size: 12px;
  }

  .title_header {
    width: 100%;
  }
}

/*HEADER*/

.custom-header {
  width: 100%;
  background: linear-gradient(
      to right,
      rgba(1, 1, 1, 0.9) 10%,
      rgba(1, 1, 1, 0.5) 50%,
      rgba(1, 1, 1, 0.1) 100%
    ),
    url("../HomePage/Photo/SOD-BUILDING.png");
  background-size: cover;
  background-position: left;
  align-items: center;
  justify-content: left;
  color: var(--white);
  position: relative;
}

.custom-header article {
  width: 50%;
  padding: 8rem 2rem;
}

.custom-header article .title {
  font-weight: 700;
}

.custom-header section {
  background-color: var(--white);
  width: 90%;
  position: absolute;
  bottom: -5rem;
  left: 50%;
  transform: translate(-50%, 2rem);
}

.custom-header section aside {
  position: relative;
}

.custom-header section aside a {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border-radius: 50%;
  background-color: var(--white);
  bottom: -15px;
  width: 30px;
  height: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid var(--gray);
}

.custom-header section aside:nth-child(1) {
  background-color: #ee4500;
}

.custom-header section aside:nth-child(2) {
  background-color: #f5ab35;
}

.custom-header section aside:nth-child(3) {
  background-color: var(--primary_lite);
}
.birthday-card {
  position: absolute;
  top: 40%;
  right: -500px; /* Start off-screen */
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  animation: slideIn 1s ease-out forwards; /* Apply the animation */
}
/* Keyframes for sliding in from the right */
@keyframes slideIn {
  from {
    right: -500px; /* Start off-screen */
  }
  to {
    right: 20px; /* End at the desired position */
  }
}

@media (max-width: 920px) {
  .custom-header article {
    width: 100%;
    padding: 4rem 1rem;
  }

  .custom-header section {
    position: static;
    width: 100%;
    transform: none;
    padding: 1rem;
  }

  .custom-header section aside {
    flex: 1 1 100%;
    margin: 1% 0;
  }

  .custom-header section aside a {
    position: relative;
    transform: none;
    background-color: none;
    border: 0;
    padding: 0;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    color: var(--white);
  }

  .custom-header section aside a:before {
    content: "Explore";
    margin-right: 5px;
    color: var(--white);
  }
}

/*division_2*/
.division_2 {
  margin-top: 10rem;
  align-items: center;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0 4rem;
}

.division_2 .title_header {
  width: 100%;
  text-align: left;
}

.division_2 .flex_content:first-child {
  background-color: #f4f4f4;
}

.division_2 .flex_content:last-child {
  background-color: #ee4500;

  /* background-color: #023047; */
}

.division_2 #notification {
  max-height: 67vh;
  height: 80vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#notification::-webkit-scrollbar {
  display: none !important;
}

.division_2 .flex_content:first-child p {
  color: var(--gray);
}

.division_2 #notification a {
  align-items: center;
  color: var(--primary_dark);
  padding: 1rem 0;
}

.division_2 #notification a:not(:last-child) {
  border-bottom: 1px solid rgba(1, 1, 1, 0.1);
}

.division_2 #notification a p {
  font-size: 12px;
}

.division_2 #notification article .title {
  margin: 0;
  position: relative;
  top: 10px;
  font-size: 0.9rem;
}

.division_2 #notification a:hover .title {
  color: var(--dark);
}

.division_2 #notification figure {
  width: 35%;
  height: 100%;
  overflow: hidden;
  margin-right: 20px;
}

.division_2 #notification figure img,
.division_2 #event figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.division_2 #notification aside {
  align-items: center;
  justify-content: space-between;
}

.division_2 span {
  font-size: 12px;
  color: var(--gray);
  margin-right: 15px;
  position: relative;
  top: -10px;
}

.division_2 .flex_content:last-child .title {
  color: var(--white);
}

.division_2 #event figure {
  width: 100%;
  height: 30vh;
  overflow: hidden;
}

.division_2 #event figure img {
  width: 50%;
}

#event ul li {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  width: 100% !important;
  overflow: hidden;
}

#event aside {
  align-items: center;
  justify-content: center;
}

#event aside span {
  padding: 0.3rem 2rem;
  background-color: var(--lite);
  font-weight: 600;
  font-size: 2em;
  text-align: center;
  text-transform: uppercase;
}

#event aside span sub {
  display: block;
  font-weight: 200;
  font-size: 12px;
  position: relative;
  top: -5px;
}

#event .btn {
  display: block;
  margin-top: 2rem;
  border-radius: 0;
  padding: 0.5rem 1rem;
  background-color: var(--white) !important;
}

@media (max-width: 920px) {
  .division_2 {
    margin-top: 0rem;
    display: block;
    padding: 1rem;
  }

  .division_2 #notification {
    max-height: 300px;
    height: 300px;
  }

  .division_2 #notification figure {
    width: 50px;
    margin-right: 0;
  }

  #event {
    margin-top: 1rem;
  }

  #event aside span {
    padding: 0.3rem 1rem;
    font-size: 1.3em;
  }
}

/*division_3*/

/*division_4*/
.division_4 {
  z-index: 11;
}

.division_4 .title_header {
  width: 50%;
}

.division_4 .title_header .btn {
  margin: auto;
  border-radius: 40px;
  padding: 0.5rem 1rem;
}

.division_4 .title_header aside {
  align-items: center;
  justify-content: center;
}

.division_4 .title_header aside .fa {
  background-color: rgba(1, 1, 1, 0.2);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: var(--gray);
}

.division_4 .title_header aside .btn {
  margin: 0 0.5rem;
}

.division_4 .title_header aside a:first-child {
  color: var(--white) !important;
}

.division_4 .title_header aside a:last-child {
  color: var(--gray);
}

.division_4 .title_header p {
  color: var(--gray);
}

.division_4 .title_header .bar {
  margin: auto;
}

.division_4 .cards {
  padding: 1rem 4rem;
  position: relative;
}

.division_4 .cards:before,
.division_4 .cards:after {
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  z-index: -1;
}

.ball {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--primary);
}

.division_4 .cards:before {
  background-image: url("https://i.postimg.cc/3NQzzcK3/dots.png");
  top: 0;
  left: 2%;
  animation: arrows 4s 0s infinite;
}

.division_4 .cards:after {
  background-image: url("https://i.postimg.cc/3NQzzcK3/dots.png");
  bottom: 0;
  right: 2%;
  animation: arrows 6s 0s infinite;
}

.ball:nth-child(1) {
  width: 30px;
  height: 30px;
  left: 10%;
  top: 5%;
  animation: arrows 10s 0s infinite;
}

.ball:nth-child(2) {
  width: 30px;
  height: 30px;
  right: 10%;
  bottom: 5%;
  animation: arrows 10s 0s infinite;
}

.ball:nth-child(3) {
  width: 50px;
  height: 50px;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  animation: arrows 10s 0s infinite;
}

.ball:nth-child(4) {
  width: 50px;
  height: 50px;
  left: 3%;
  bottom: 0%;
  animation: arrows 15s 0s infinite;
}

.division_4 .cards figure {
  height: 70vh;
  margin: 2%;
  border-radius: 140px;
  overflow: hidden;
  position: relative;
}

.division_4 .cards figure:nth-child(2),
.division_4 .cards figure:nth-child(3) {
  top: 4rem;
}

.division_4 figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.division_4 .cards section {
  background-image: url("https://i.postimg.cc/g0Jw6Mbc/line.png");
  background-size: 70% auto;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

@media (max-width: 920px) {
  .division_4 .title_header {
    width: 100%;
    padding: 1rem;
  }

  .division_4 .cards figure {
    height: 200px;
  }

  .division_4 .cards:after {
    display: none;
  }
}

/*Transparent text background*/
.stroke {
  color: transparent;
  -webkit-text-stroke: 1.5px var(--primary_lite);
}

/*Custom cursor coloring*/
.cursor--expand {
  animation: cursor-animate-3 550ms forwards;
  border: 10px solid rgb(var(--primary_dark));
}

.cursor--expand::after {
  border: 15px solid rgba(var(--primary_dark), 0.3);
}

/*Scroller*/
.cs-down {
  position: absolute;
  height: 35px;
  width: 20px;
  border-radius: 10px;
  border: 2px solid var(--white);
  bottom: 10px;
  left: 50%;
  margin-left: -10px;
  z-index: 11;
}

.cs-down:before {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: var(--white);
  -webkit-animation: UpAndDown 3s infinite;
  animation: UpAndDown 3s infinite;
}

@-webkit-keyframes UpAndDown {
  0%,
  100% {
    top: 30%;
  }
  50% {
    top: 70%;
    opacity: 0.5;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
    transform: translate(-50%, -50%) scale(0.8);
  }
}

@keyframes UpAndDown {
  0%,
  100% {
    top: 30%;
  }
  50% {
    top: 70%;
    opacity: 0.5;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
    transform: translate(-50%, -50%) scale(0.8);
  }
}

/*ZOOM IN*/
.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/*RIPPLE*/
.ripple {
  border-radius: 100%;
}

.ripple::after {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--primary);
  border-radius: 100%;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
}

@-webkit-keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(2, 2, 1);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(2, 2, 1);
  }
}

.bar {
  display: block;
  height: 5px;
  width: 100px;
  background: var(--primary);
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin: 20px auto 20px 0;
}

.bar:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: var(--white);
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

@keyframes MOVE-BG {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(105px);
    transform: translateX(105px);
  }
}

.bar_blue,
.bar_white {
  display: block;
  width: 100px;
  height: 3px;
}

.bar_blue {
  background-color: #ef4a4a;
}

.bar_white {
  background-color: var(--white);
}

/*Ripple effect*/
@-webkit-keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(2, 2, 1);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(2, 2, 1);
  }
}

@keyframes arrows {
  0%,
  100% {
    color: black;
    transform: translateY(0);
  }
  50% {
    color: #3ab493;
    transform: translateY(20px);
  }
}

@keyframes fixed_nav {
  0%,
  100% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*Blinking live dot*/
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
}

.ringring {
  /* -webkit-border-radius: 30px; */
  height: 18px;
  width: 18px;
  position: absolute;
  left: 0.3px;
  top: 0.3px;
  /* -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;  */
  opacity: 0;
}

.ringring_green {
  border: 3px solid #62bd19;
}

.circle_green {
  background-color: #62bd19;
}

.ringring_red {
  border: 0px solid red;
}

.circle_red {
  background-color: red;
}

.nav_content {
  display: flex;
  justify-content: space-around;
  /* background-color: #333; */
  color: rgb(235, 230, 230);
  /* padding: 10px; */
}

/* Hide the dropdown content by default */

/* .navbar-nav .nav-link {
  color: #fff;
} */
/* .dropend .dropdown-toggle {
  color: salmon;
  margin-left: 1em;
} */
/* .dropdown-item:hover {

  color: #fff;
} */
/* .dropdown .dropdown-menu {
  display: none;
} */
/* .dropdown:hover > .dropdown-menu,
.dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 0.125em;
  margin-left: 0.125em;
} */
/* @media screen and (min-width: 769px) {
  .dropend:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .dropend .dropdown-toggle {
    margin-left: 0.5em;
  }
}





.dropdown > a {
  display: flex;
  align-items: center;
}
.dropdown > a .icon {
  margin-left: 0.25em;
}


.arw4::after {
  
  border-bottom-style: solid;
  
  border-bottom-width: 2px;
  
  border-right-style: solid;
  
  border-right-width: 2px;
  
  content: '';
  
  display: inline-block;
  
  height: 12px;
  
  left: 0;
  
  position: absolute;
  
  top: 0;
  
  width: 12px;
  
  }
  
  .arw4.is-triangle::after {
  
  border-style: solid;
  
  border-width: 0 0 12px 12px;
  
  height: 0;
  
  width: 0;
  
  }
  
  
  
 
  
  
  
  .arw4.is-right {
  
  -moz-transform: rotate(315deg);
  
  -ms-transform: rotate(315deg);
  
  -webkit-transform: rotate(315deg);
  
  transform: rotate(315deg);
  
  }
  
.arw4.is-triangle::after,
  
  .arw4 .arw4.is-triangle::after {
  
  border-color: transparent;
  
  border-bottom-color:#336699;
  
  } */
